.PersonalDetails {
  padding: 0.5rem 2rem;
}

.PersonalDetails input {
  font-size: 16px;
}

.PersonalDetails .ant-select-selection {
  border: none !important;
}

.PersonalDetails .mainHeading {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.PersonalDetails .inputContainer {
  padding: 1rem;
}

.bookingDetails {
  width: auto;
}

.doubleInput {
  display: flex;
}

.bookingHotelInfo .subHeading {
  color: #bfbfbf;
}

.bookingHotelInfo {
  /* padding: 2rem 0; */
  margin-bottom: 2rem;
  border-bottom: 1px solid #E9EDEF;
}

.bookingDetails .crednum {
  border: none;
  width: calc(100vw - 9.5rem);
}

.cardinfo input {
  padding: auto 1rem;
}

.PersonalDetails .topheading {
  color: black;
  font: bolder;
  font-size: 2rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PersonalDetails .profiletelephoneinput {
  width: 100% !important;
  border: none !important;
}

.PersonalDetails .react-tel-input .flag-dropdown {
  border: none;
}

.PersonalDetails .react-tel-input .form-control:focus {
  box-shadow: 0 0 0 2px rgba(217, 59, 79, 0.2);
}

.PersonalDetails .imageDiv {
  background-size: cover;
  background-position: center center;
  height: 20vh;
  margin: 1rem 0;
}

.PersonalDetails .bookingModalInfo {
  margin-right: 2rem;
  margin-bottom: 1rem;
}

.PersonalDetails .flexcol{
  display: flex;
  flex-direction: column;
}

.PersonalDetails .flexSpWrap {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (min-width: 776px) {
  .PersonalDetails {
    width: 75vw;
    margin: auto;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 0;
  }
  .PersonalDetails .bookingDetails {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 4rem;
  }
  .PersonalDetails .bookingHotelInfo {
    width: 24vw;
  }
  .PersonalDetails .bookingDetailsContainer {
    width: 50vw;
  }
  .PersonalDetails .inputContainer {
    width: 24.5vw;
  }

  .PersonalDetails .inputContainer.title{
    width: 10.5vw;
  }

  .PersonalDetails .inputContainer.firstname,
  .PersonalDetails .inputContainer.lastname {
    width: 24.5vw;
  }


  .PersonalDetails .doubleInput {
    width: 24.5vw;
  }
  .PersonalDetailsContainer .mainHeading {
    margin: 4rem auto;
  }
  .bookingHotelInfo .infoBox {
    margin: 1rem 0;
    padding: 1rem;
    border: 1px solid #E9EDEF;
  }
  .bookingHotelInfo .outerBox {
    border: 1px solid #E9EDEF;
    padding: 2rem 1rem;
  }
  .bookingHotelInfo .outerBoxSpacer {
    height: 9rem;
  }
  .bookingDetails .crednum {
    border: none;
    width: 20vw;
  }
}

.StripeElement {
  margin-top : 20px;
  margin-bottom : 20px;
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}
.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}
.StripeElement--invalid {
  border-color: #fa755a;
}
.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
