.DesktopSkeleton {
    margin-top: 5rem;
}

.DesktopSkeleton .title .ant-skeleton-paragraph>li+li {
    margin-top: 0;
}

.DesktopSkeletonInfo {
    margin-top: 2rem;
    margin-bottom: 4rem;
}

.DesktopSkeletonInfo .ant-skeleton-paragraph>li+li {
    margin-top: 0;
}

.DesktopSkeleton .middle {
    display: flex;
    flex-wrap: wrap;
}

 .modalOverlay {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    position: fixed;
    background-color: rgba(227, 225, 234, 0.6);
    z-index: 5;
}

.modalOverlay .overlayContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: 500;
    height: 100vh;
    width: 100%;
    z-index: 10;
    opacity: 1;
}

.RoomSkeleton {
    padding: 1rem;
}

.RoomSkeleton .image .ant-skeleton-paragraph>li+li {
    margin-top: 0;
}

.DesktopSkeleton .RoomSkeleton {
    width: calc(75vw / 4);
}

.MobileSkeleton {
    padding: 1rem 2rem;
}

.MobileSkeleton .title {
    margin-top: 1rem;
}

.MobileSkeleton .title .ant-skeleton-paragraph>li+li {
    margin-top: 0;
}

.MobileSkeleton .filters {
    margin-top: 2rem;
}

.MobileSkeleton .filters .ant-skeleton-paragraph>li+li {
    margin-top: 0;
}