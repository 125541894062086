.borderlessInput * {
  border: none !important;
}

.maincontainer {
  padding: 0.5rem 2rem;
}

.logo-img {
  width: 50%;
  height: auto;
}

.Profileheader {
  font-size: 1.5rem;
  color: red;
}

.ant-form-item-label {
  text-align: left;
}

.prefixphone > .ant-form-item-control-wrapper > .ant-form-item-control {
  line-height: 0px;
}

.profileContainer {
  display: flex;
  flex-direction: column;
}

.columnContainer {
  width: auto;
}

.profileouterContainer {
  padding: 1.75rem;
}

.profileouterContainer .ant-select-selection {
  border: none !important;
}

.menuContainer .ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #f48148 !important;
  border-bottom: 2px solid #f48148 !important;
}

.menuContainer .ant-menu-item {
  color: black;
}

.profiletelephoneinput {
  width: 100% !important;
  border: none !important;
}

.profileContainer .react-tel-input .flag-dropdown {
  border: none;
}

.profileContainer .react-tel-input .form-control:focus {
  box-shadow: 0 0 0 2px rgba(217, 59, 79, 0.2);
}

@media (max-width: 767px) {
  .menuContainer {
    width: 100vw;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    height: 6rem;
  }
  .menuContainer > .menuItem {
    flex: 1;
    margin: 0 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .logo-img {
    width: 20%;
  }
  .maincontainer {
    padding: 2rem 5rem;
  }
  .Profileheader {
    font-size: 2rem;
    color: red;
  }
  .profileContainer {
    flex-direction: row;
    justify-content: space-between;
    width: 60vw;
    margin-right: auto;
  }
  .profileouterContainer {
    width: 75vw;
    margin: auto;
    padding-right: auto;
  }
  .menuContainer {
    width: 75vw;
    margin: 5rem auto !important;
  }
  .columnContainer {
    width: 25vw;
  }
  .profileouterContainer {
    padding: 0;
  }
}
