.SingleBooking .heading {
  color: #141414;
  font-size: 2rem;
  font-weight: bolder;
}

.SingleBooking .subheading {
  font-size: 1.5rem;
  font-weight: 475;
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.SingleBooking .Searchstars {
  font-size: 1.25rem !important;
  color: #d93b4f;
  margin: 0.25rem 0;
}

.SingleBooking .hotelImage {
  background-repeat: no-repeat;
  background-size: cover;
  width: auto;
  height: 20rem;
  width: auto;
  margin: 1.5rem 0;
}

.SingleBooking .headingFormatter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.SingleBooking .infoContainer {
  display: flex;
  flex-direction: column;
  padding: 1.25rem 0;
  border-bottom: 1px solid #E9EDEF;
}

.SingleBooking .infodesc {
  font-weight: 450;
}

.SingleBookingSide {
  display: none;
}

.SingleBooking .bookingid {
  color: var(--vdOrange);
}

@media screen and (min-width: 776px) {
  .SingleBooking .infoContainer {
    flex-direction: row;
  }
  .SingleBooking .infodesc {
    flex: 1
  }
  .SingleBooking .bookinginfo {
    flex: 2;
  }
  .SingleBookingMain {
    flex: 4;
    padding-right: 2rem;
  }
  .SingleBookingSide {
    flex: 1;
    width: 100%;
    height: 30vh;
    display: flex;
  }
  .SingleBooking {
    padding: 0;
    display: flex;
  }
  .SingleBooking .hotelImage {
    display: none;
  }
  .SingleBooking .subheading {
    font-size: 2rem;
  }
  .SingleBooking .headingFormatter {
    flex-direction: row;
    justify-content: space-between;
  }
}
