.loading {
    display: flex;
    justify-content: center;
    align-Items: center;
    height: 50vh;
}

.RoomsAndRatesContainer {
    width: calc(100vw - 3.5rem);
}

.combinationCostscontainer {
    display: flex;
    flex-direction: column;
    width: calc(100vw - 3.0rem);
    height: auto;
    margin-top: 2rem;
}

.combinationImageName {
    display: flex;
    flex-direction: row;
}

.RoomsImageContainer {
    height: 25vw;
    width: calc(100vw - 3.5rem);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow-x: auto;
    scrollbar-width: none;
}

.RoomsImage {
    height: 25vw;
    width: 33vw;
    flex: 0 0 auto;
    background-size: cover;
    margin-right: 2px;
    background-position: center center
}

.combinationCostscontainerroomName {
    font-weight: bold;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
    margin-left: 1.0rem;
}

.facilitiesContainerButton{
    padding-left: 1rem;
}

.facilities {
    display: flex;
    flex-wrap: wrap;
}

.facility {
    margin: 0.25rem 0;
    width: 50%;
}

.roomsPrices {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: calc(100vw - 3.5rem);
    height: auto;
    margin: auto;
}


.Facilities {
    width: calc(100vw - 5.5rem);
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #E9EDEF;
    align-items: center;
    margin: 0 auto;
}

.shadow {
    -webkit-box-shadow: 1px 1px 10px 0px #ccc, -1px -1px 10px 0px #ccc;
    /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: 1px 1px 10px 0px #ccc, -1px -1px 10px 0px #ccc;
    /* Firefox 3.5 - 3.6 */
    box-shadow: 1px 1px 10px 0px #ccc, -1px -1px 10px 0px #ccc;
    /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}

.facilitiesContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1rem;
}


@media screen and (min-width: 776px) {
    .RoomsAndRatesContainer {
        width: 60vw;
    }
    .roomsPrices {
        width: calc(60vw - 2rem);
        justify-content: space-between;
    }
    .combinationCostscontainer {
        width: 60vw;
        margin-bottom: 4rem;
        align-items: center;
    }
    .RoomsImageContainer {
        width: calc(60vw - 2rem);
    }
    .combinationCostscontainerroomName {
        font-size: 2rem;
        font-weight: normal;
    }
    .facility {
        width: 25%;
        margin: 1rem 0;
    }
    .RoomsImage {
        height: 20vh;
        width: 30vh;
    }
    .RoomsImageContainer {
        height: 20vh;
        margin: 1rem 0;
    }
    .Facilities {
        width: 19vw;
        margin: 0;
    }
    .facilitiesContainer{
        width: calc(60vw - 2rem);
    }

}
