.containerHorizontalBetweenVerticalCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.roundedButton {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.roundedButton.filled {
  background-color: red;
}

.Home .Hero {
  /* height: auto; */
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom right;
  background-image: url('./../../images/Home/Hero2.jpeg');
  background-position-y: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Home .IndoSatHero {
  height: 80vh;
}

@media screen and (max-width: 992px) {
  .Home .IndoSatHero {
    height: 90vh;
  }
}

@media screen and (max-width: 1200px) {
  .Home .IndoSatHero {
    height: 90vh;
  }
}

.Home .HeroCenter {
  width: 75vw;
  margin: auto;
  margin-bottom: 35px;
}

.Home .HeroIndoSatCenter {
  width: 75vw;
  /* margin: auto; */
  margin-bottom: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 40px;
}

.Home .HeroText {
  font-size: 3rem;
  width: 50vw;
  font-weight: 700;
  color: black;
  height: 22vh;
  margin-bottom: 45px;
}

.Home .HeroTextBottom {
  font-weight: 700;
  margin-top: 0.5rem;
  color: white;
}

.Home .IndoSatHeroTextBottom {
  font-weight: normal;
  margin-top: 1rem;
  color: white;
  text-align: center;
}

.Home .PopularDestination {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
}

.Home .smallImage {
  height: 18vw;
  width: 18vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}

.Home .largeImage {
  height: 24vw;
  width: 37vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}

.PopularDestination .text {
  font-weight: 500;
  margin-top: 1rem;
  cursor: pointer;
}

.PopularDestination .subText {
  font-weight: 400;
  color: #858586;
  cursor: pointer;
}

.Home .Row {
  width: 75vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 3rem auto;
  margin-bottom: 5rem;
  flex-wrap: wrap;
}

.Home .homeHeading {
  font-weight: 700;
  width: 75vw;
  margin: 0 auto;
  font-size: 2.5rem;
  margin-top: 3rem;
}

.Home .IndosatPopularDestionationsContainer {
  width: 75vw;
  margin: 3rem auto 5rem;
  display: flex;
  flex-direction: column;
}

.Home .IndosatPopularDestionationsContainer .first-row {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.Home .IndosatPopularDestionationsContainer .second-row {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
}

.Home .IndosatPopularDestionationsContainer .tnc-row {
  margin-top: 10px;
  width: 100%;
  background: #f9f9f9;
  padding: 5px 5px;
  display: flex;
  align-items: center;
  margin: 40px 0;
}

.Home .tnc-row .tnc-img-container {
  width: 80px;
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-right: 15px;
  margin-left: -20px;
  flex: 2;
}

.Home .tnc-row .tnc-title-container {
  display: flex;
  text-align: left;
  flex: 10;
}

.Home .tnc-row .tnc-title-container .tnc-title {
  font-size: 24px;
  font-weight: bold;
}

.Home .tnc-row .tnc-title-container .tnc-subtext {
  font-size: 16px;
}

.Home .tnc-row .tnc-open-modal-btn {
  display: flex;
  align-items: center;
  height: 100%;
  flex: 2;
}

.Home .tnc-row .tnc-open-modal-btn button {
  padding: 12px;
  background-color: #ed2324;
  color: white;
  cursor: pointer;
  min-width: 144px;
  border: none;
  border-radius: 25px;
  font-size: 14px;
  font-weight: 700;
}

.Home .IndosatPopularDestionationsContainer .indosat-img-carousel {
  margin: 20px 0px;
}

.Home .indosat-img-carousel .ant-carousel .slick-dots li {
  margin-top: 1rem;
}

.Home .indosat-img-carousel .ant-carousel .slick-dots .slick-active > button {
  background-color: #eb234a;
}

.Home .indosat-img-carousel .indosat-carousel-item {
  width: 100%;
  position: relative;
}

.Home .indosat-img-carousel .indosat-carousel-item .centered-overlay-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.Home .indosat-carousel-item .centered-overlay-title .overlay-title-text {
  font-size: 4rem;
  font-weight: 800;
  text-align: center;
}

.Home .indosat-carousel-item .centered-overlay-title .overlay-title-link {
  font-size: 16px;
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
}

.Home .indosat-img-carousel .indosat-carousel-item .carousel-img {
  width: 100%;
  height: 40vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: multiply;
}

.indosat-swiper {
  position: relative;
}

.indosat-swiper-slide-thumbnail {
  width: 100%;
  height: 150px;
  background-size: cover;
  object-fit: contain;
  object-position: center;
  border-radius: 20px;
}

.indosat-swiper-slide-title {
  font-size: 12px;
  text-align: center;
  margin-top: 14px;
}

.swiper-arrow {
  width: 25px;
  height: 25px;
  background-color: #ffffff;
  border-radius: 50%;
  background-repeat: no-repeat;
  border: none;
  outline: none;
  position: absolute;
  z-index: 9999;
  top: 35%;
}

.swiper-arrow.next {
  background-image: url('../../images/icons/chevron-arrow-right.svg');
  right: 0;
  /* transform: rotate(180deg); */
}

.swiper-arrow.prev {
  /* right:0; */
  background-image: url('../../images/icons/chevron-arrow-left.svg');
}

.voodo-voucher-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  position: relative;
}

.Home .voodo-voucher-container .voodo-voucher-img {
  margin: auto;
  width: auto;
  height: 30rem;
  text-align: center;
}

.Home .voodo-voucher-container .voucher-overlay-text {
  position: absolute;
  margin-top: 4rem;
  margin-left: 27rem;
  color: white;
  font-size: 20px;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}

.Home .IndosatPopularDestination {
  width: 100%;
  position: relative;
  /* margin: 10px 0; */
  margin-bottom: 16px;
}

.Home .IndosatPopularDestination .largeImage {
  height: 16vw;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  border-radius: 2rem;
}

.Home .IndosatPopularDestination .smallImage {
  height: 16vw;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  border-radius: 2rem;
}

.Home .IndosatPopularDestination .top-left {
  position: absolute;
  top: 16px;
  left: 20px;
  color: white;
  font-weight: 700;
  display: flex;
  gap: 5px;
}

.Home .IndosatPopularDestination .des-flag-img {
  height: 15px;
  width: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.HomeMobile .PopularDestination {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
}

.HomeMobile .Header {
  height: auto;
}

.carouselContainer {
  height: 80vw;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.HomeMobile .smallImage {
  height: 60vw;
  width: 60vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.carouselContainerDots {
  background-color: rgba(235, 35, 74, 0.1);
  border-radius: 5rem;
  margin: 1rem;
  height: 1rem;
  width: 1rem;
}

.HomeMobile .ant-carousel .slick-dots > li > button {
  background-color: rgba(235, 35, 74, 0.3);
  border-radius: 5rem;
  margin: 1rem;
  height: 1rem;
  width: 1rem;
}

.HomeMobile .ant-carousel .slick-dots .slick-active > button {
  background: #eb234a;
}

.HomeMobile .homeHeading {
  font-weight: 700;
  padding: 1rem 1.75rem;
  /* width: 70vw; */
  width: 100%;
  font-size: 2rem;
  /*margin-top: 2.5rem;*/
}

.HomeMobile .mobileImage {
  height: 60vw;
  width: calc(100vw - 3.5rem);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.HomeMobile .IndosatPopularDestionationsContainer {
  width: 90vw;
  margin: 3rem auto 5rem;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 991px) {
  .HomeMobile .IndosatPopularDestionationsContainer {
    gap: 30px;
  }
}

.HomeMobile .IndosatPopularDestionationsContainer .first-row {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

.HomeMobile .IndosatPopularDestionationsContainer .second-row {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
}

.HomeMobile .IndosatPopularDestionationsContainer .tnc-row {
  margin-top: 10px;
  width: 100%;
  background: #f9f9f9;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.HomeMobile .tnc-row .tnc-img-container {
  width: 80px;
  height: 80px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: none;
}

.HomeMobile .tnc-row .tnc-title-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.HomeMobile .tnc-row .tnc-title-container .tnc-title {
  font-size: 20px;
  font-weight: 600;
}

.HomeMobile .tnc-row .tnc-title-container .tnc-subtext {
  font-size: 16px;
}

.HomeMobile .tnc-row .tnc-open-modal-btn {
  display: flex;
  align-items: center;
  height: 100%;
}

.HomeMobile .tnc-row .tnc-open-modal-btn button {
  padding: 12px;
  background-color: #ed2324;
  color: white;
  cursor: pointer;
  min-width: 144px;
  border: none;
  border-radius: 25px;
  font-size: 14px;
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .HomeMobile .IndosatPopularDestionationsContainer .indosat-img-carousel {
    margin: 20px 0px;
  }
}

.HomeMobile .indosat-img-carousel .ant-carousel .slick-dots li {
  margin-top: 1rem;
}

.HomeMobile
  .indosat-img-carousel
  .ant-carousel
  .slick-dots
  .slick-active
  > button {
  background-color: #eb234a;
}

.HomeMobile .indosat-img-carousel .indosat-carousel-item {
  width: 100%;
  position: relative;
}

.HomeMobile
  .indosat-img-carousel
  .indosat-carousel-item
  .centered-overlay-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.HomeMobile .indosat-carousel-item .centered-overlay-title .overlay-title-text {
  font-size: 4rem;
  font-weight: 800;
  text-align: center;
}

.HomeMobile .indosat-carousel-item .centered-overlay-title .overlay-title-link {
  font-size: 16px;
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
}

.HomeMobile .indosat-img-carousel .indosat-carousel-item .carousel-img {
  width: 100%;
  height: 60vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: multiply;
}

.HomeMobile .IndosatPopularDestination {
  width: 100%;
  position: relative;
  margin: 2rem 0;
}

.HomeMobile .IndosatPopularDestination .largeImage {
  height: 60vw;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  border-radius: 2rem;
}

.HomeMobile .IndosatPopularDestination .smallImage {
  height: 24vw;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  border-radius: 2rem;
}

.HomeMobile .IndosatPopularDestination .top-left {
  position: absolute;
  top: 16px;
  left: 20px;
  color: white;
  font-weight: 700;
  display: flex;
  gap: 5px;
}

.HomeMobile .IndosatPopularDestination .des-flag-img {
  height: 15px;
  width: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.HomeMobile .voodo-voucher-container .voodo-voucher-img {
  margin: auto;
  width: 100%;
  height: auto;
  text-align: center;
}

.HomeMobile .voodo-voucher-container .voucher-overlay-text {
  position: absolute;
  margin-top: 0;
  margin-right: 0;
  top: 50%;
  right: 10vw;
  color: white;
  font-size: 16px;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}

.tnc-modal {
  width: 80vw !important;
  margin: auto;
}

.yoodo-tnc-modal-content {
  padding: 1rem;
}

.yoodo-tnc-modal-content .tnc-sub-list {
  position: relative;
  left: 2rem;
}

.tnc-modal .ant-modal-title {
  font-size: 30px;
  font-weight: bold;
  padding: 30px !important;
  line-height: 30px;
}

@media screen and (max-width: 550px) {
  .tnc-modal .ant-modal-title {
    text-align: center;
  }
}

.business-dashboard-link {
  color: #03398a;
  font-size: 24px;
  position: relative;
  z-index: 2;
}

.business-dashboard-link.active,
.business-dashboard-sub-link.active {
  font-weight: bold;
}

.business-dashboard-sub-links-group {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 20px;
  row-gap: 10px;
  opacity: 1;
  transition: all 0.2s;
  z-index: 1;
}

.business-dashboard-sub-links-group.hidden {
  position: absolute;
  opacity: 0;
}

.business-dashboard-sub-link {
  color: #03398a;
  font-size: 18px;
}

.ReactModal__Overlay {
  z-index: 10;
}

/* BUSSINESS PAGE - SUPPORT - START */
.contactLogo {
  width: 200px;
  height: 200px;
}

.contactContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.contactPhoneItem {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.contactMailItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.contactIcon {
  width: 25px;
  height: 25px;
}

.contactLabel {
  font-size: 15px;
  margin-left: 5px;
  color: black;
}

.contactLabel:hover {
  color: black;
}

/* BUSSINESS PAGE - SUPPORT - END */

/* BUSSINESS PAGE - PAYMENTS - START */
.cardsList {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2rem;
}

.cardInfoText {
  white-space: nowrap;
  margin: 0;
}

.addCardGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
}

.inputText {
  outline: none;
  border: 1px solid lightgray;
  width: 100%;
  padding: 5px 5px;
}

.cardBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid #ed2324;
    background: rgb(249, 249, 249); */
  column-gap: 20px;
  width: 100%;
  /* padding: 20px 20px;
    border-radius: 10px; */
}

.cardBoxContentWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 40px;
  font-weight: normal;
}

.cardBoxContent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
}

.cardBoxNumber {
  margin: 0;
}

.cardBoxExpiry {
  color: grey;
  margin-top: 0.5rem;
}

.cardBoxTitle {
  color: rgb(107, 107, 107);
  margin-top: 0.5rem;
}

.cardBoxActionButton {
  outline: none;
  border: none;
  background-color: transparent;
  color: #2680e9;
  font-weight: bold;
}

.cardBoxActionButton:hover {
    color: #0b325f;
}

.addNewCardButton {
  width: 100%;
  /* background-color: transparent; */
  background-color: #dbebff70;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2680e9;
  font-weight: bold;
  padding: 10px 0;
  border-radius: 8px;
  border: none;
  outline: none;
}

.addNewCardButton:hover {
  background-color: rgba(167, 203, 246, 0.587);
  transition: all 0.3s;
}
/* 
.cardBoxActionButton:hover {
    cursor: pointer;
} */

/* BUSSINESS PAGE - PAYMENTS - END */

/* BUSSINESS PAGE - TEAM - START */
.titleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.teamSection {
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inviteTeamModal .modalWrapper {
  min-width: 500px;
}

.inviteTeamModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  resize: auto;
}

@media screen and (min-width: 991px) {
  .inviteTeamModal .modalWrapper {
    min-width: 500px;
  }
}

.inviteTeamModal .checkboxGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
}

.inviteTeamModal ul {
  margin-left: 10px;
}

/* BUSSINESS PAGE - TEAM - END */
/* BUSSINESS PAGE - COMPANY DETAILS - START */
/* .companyDetailsPage .inputContainer, 
.companyDetailsPage .ant-form-item,
.companyDetailsFormItem, */
.teamSection .inputContainer,
.companyDetailsPage .inputContainer {
  width: 100% !important;
  border: 1px solid #e9edef;
  margin-bottom: 1rem;
  padding-bottom: 0;
  background-color: white;
  padding: 0 !important;
  margin-top: 2rem;
}

.teamSection .inputDescription,
.companyDetailsPage .inputDescription {
  margin: 4px;
  font-size: 12px;
}

.teamSection .businessLogoThumbnail,
.companyDetailsPage .businessLogoThumbnail {
  /* width: 70px; */
  height: 70px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.teamSection .ant-input,
.companyDetailsPage .ant-input {
  font-size: 12px;
  /* height: 10px; */
}

.teamSection .ant-form-explain,
.companyDetailsPage .ant-form-explain {
  font-size: 12px;
}

/* BUSSINESS PAGE - COMPANY DETAILS - END */
/* BUSSINESS PAGE - BOOKINGS - START */
.status-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: white;
}

/* BUSSINESS PAGE - BOOKINGS - END */
.dt-layout-cell,
.dataTable {
  width: 100% !important;
}

.dataTable {
  margin: 30px 0;
}

.dataTable,
.dataTable th,
.dataTable td {
  border: 1px solid lightgray;
  border-collapse: collapse;
}

.dataTable th,
.dataTable td {
  padding: 5px;
}

.dt-search {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 10px;
}

.dt-search .dt-input {
  border: 1px solid lightgray;
  outline: none;
}

.dt-length {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
}

.dt-layout-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.dt-info {
  text-align: left;
}

.dt-paging {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.paging_full_numbers {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  column-gap: 4px;
}

.dt-paging-button {
  background-color: lightgrey;
  outline: none;
  border: none;
  width: 20px;
  color: black;
}

.dataTable .action-button,
.dataTable .edit-button,
.dataTable .delete-button {
  background-color: transparent;
  outline: none;
  border: none;
}

.dataTable .action-button {
  color: royalblue;
}

.dataTable .edit-button {
  color: orange;
}

.dataTable .delete-button {
  color: orangered;
}

/* Accept Invitation Page */
.acceptInvitationForm {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
}

.acceptInvitationForm .ant-form-item {
  margin-bottom: 0 !important;
}
