
.provider-form .ant-form-item {
  margin-bottom: 4px !important;
}

.provider-form .ant-form-item-label {
  line-height: normal;
}

.provider-form label {
  font-size: 12px !important;
  line-height: normal !important;
  font-family: "Inter", sans-serif !important;
  font-weight: 600 !important;
}

.provider-form .text {
  font-size: 12px !important;
}

.ant-form-item-children {
  font-size: 12px !important;
}

.provider-form .react-tel-input .form-control:focus {
  box-shadow: 0 0 0 2px rgba(217, 59, 79, 0.2);
}

.provider-form .react-tel-input .flag-dropdown {
  border: none;
}
