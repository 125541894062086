.SearchResultsMobile .SearchContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1.75rem;
}

.SearchResultsMobile .room {
  width: calc(100vw - 3.5rem);
  height: calc(100vw - 3.5rem);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.SearchResultsMobile .roomimage {
  height: calc( 0.5 * (100vw - 3.5rem));
  width: calc(100vw - 3.5rem);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.SearchResultsMobile .resultsFor {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SearchResultsMobile .searchHeading {
  font-weight: bold;
  font-size: 1.5rem;
}

.SearchResultsMobile .resultsFor .searchHeading {
  font-size: 1.6rem;
}

.SearchResultsDesktop {
  width: 75vw;
  margin: auto;
}

.SearchResultsDesktop .SearchContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.SearchResultsDesktop .room {
  width: 18vw;
  height: 26vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.SearchResultsDesktop .roomimage {
  height: 13.5vw;
  width: 18vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.SearchResults .SearchResultsRoomType {
  background-color: #141414;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  margin-right: 1rem;
  border-radius: 2px;
}

.ant-input-number-handler-wrap {
  display: none;
}

.SearchResults .roomInfo {
  display: flex;
  font-size: 1.25rem !important;
  align-items: center;
  margin: 1rem 0;
}

.SearchResults .Searchstars {
  font-size: 1rem !important;
  color: #d93b4f;
}

.SearchResultsDesktop .searchHeading {
  font-weight: 500;
  font-size: 1.5rem;
}

.SearchContainer .subHeading {
  font-weight: 400;
  color: #858586;
}

.SearchContainer .SearchRates {
  color: #F48247;
  font-size: 1.5rem;
  margin: 1.5rem 0;
}

.ant-checkbox-inner {
  background-color: white !important;
}

.SearchContainer .HotelIcons {
  font-size: 2rem;
}

.HotelIcons>svg {
  margin-right: 0.6rem;
}

@media screen and (min-width: 776px) {
  .SearchContainer .SearchRates {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }
  .HotelIcons {
    margin-top: auto;
    display: flex;
  }
  .HotelIcons>svg {
    margin-right: 0.5rem;
  }
  .SearchResultsDesktop .room {
    height: 26vw;
  }
}

@media screen and (min-width: 1750px) {
  .SearchResultsDesktop .room {
    height: 23vw;
  }
}

@media screen and (min-width: 2000px) {
  .SearchResultsDesktop .room {
    height: 21vw;
  }
}

@media screen and (min-width: 2250px) {
  .SearchResultsDesktop .room {
    height: 20vw;
  }
}