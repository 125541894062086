.docsHeading {
    font-size: 2rem;
    color: black;
    margin: 2rem auto;
    margin-left: 0;
}

.docsPara {
    font-size: 1.5rem;
    color: #858586;
}

.docsOrderedListAlphabet {
    list-style-type: lower-alpha;
}

.docsOrderedListAlphabet,
.docsParagraph {
    margin-left: 30px;
}

.docsContainer {
    width: 85vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

.docsPage {
    height: auto;
    display: flex;
    flex-direction: column;
}

.docsSubHeading {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 1rem 0;
    color: black;
}

.aboutUsMainContainer .docsSubHeading {
    font-size: 1.5rem;
}

.aboutUsMainContainer .docsHeading {
    font-weight: normal;
    color: white;
}

.aboutUsMainContainer {
    display: flex;
    flex-direction: column;
    height: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 3rem
}

.submitButton {
    width: min-content;
    height: min-content;
}

.aboutUs {
    display: flex;
    flex-direction: column;
    margin: auto;
    height: 50vh;
    align-items: center;
}

.contactUs {
    display: flex;
    flex-direction: column;
    /* margin: auto; */
    align-items: center;
}

.submitButton {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.inputContainer {
    width: 100%;
    border: 1px solid #E9EDEF;
    margin-bottom: 2rem;
    padding-bottom: 0;
    background-color: white;
}

.inputDescription {
    color: #858586;
    margin-top: 0.5rem;
    margin-left: 1rem;
}

.formClass {
    background-color: transparent;
    padding: 1rem;
    width: 100%;
}

.formContainer {
    padding: 1rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.contactUsSide {
    color: white;
    width: 100%;
    margin-bottom: 5rem;
}

.docsbackground {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

@media screen and (min-width: 776px) {
    .aboutUs {
        flex-direction: column;
        width: 50vw;
    }
    .aboutUsMainContainer {
        padding: auto;
        height: 80vh;
    }
    .aboutUsMainContainer .docsHeading {
        font-weight: bold;
        color: black;
    }
    .aboutUsMainContainer .docsSubHeading {
        font-size: 2rem;
    }
    .docsContainer {
        width: 75vw;
        margin-bottom: 5rem;
    }
    .docsPara {
        font-size: 1.25rem;
        color: #858586;
    }
    .docsSubHeading {
        margin-top: 3rem;
        margin-bottom: 2rem;
        font-weight: normal;
    }
    .docsHeading {
        font-size: 3rem;
        font-weight: bold;
    }
    .contactUs {
        flex-direction: row-reverse;
        align-items: flex-start;
    }
    .contactUsSide {
        display: flex;
        flex-direction: column;
        width: 50vw;
        align-items: center;
        justify-content: flex-start;
        font-size: 3rem;
        color: white;
        margin: 0 5rem;
    }
    .formClass {
        width: 25vw;
        background-color: white;
        padding: 1rem !important;
    }
    .formContainer {
        padding: 5rem;
    }
}