.LPFooter {
    display: none;
    position: relative;
}

.LowerFooter {
    display: none;
    position: relative;
}

.FooterColumn>span {
    margin: 0.5rem 0
}

.footersocial {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 3rem;
    flex-direction: row;
    width: 20vw;
}

.FooterMobile {
    display: flex;
    flex-direction: column;
    padding: 1.75rem;
    border-top: 1px solid rgb(0, 0, 0, 0.1);
    margin-top: 2rem;
}

.FooterMobile .logo {
    margin-right: auto;
    background-size: contain;
    background-image: url("/assets/images/logo-full.png");
    height: 5vh;
    width: 16.666vh;
}

.FooterMobile>div {
    margin: .5rem 0;
}

.FooterMobile div a {
    color: black;
}

.FooterMobile .currency {
    display: flex;
    /*justify-content: space-between;*/
    align-items: center;
    border-top: 1px solid rgb(0, 0, 0, 0.1);
    border-bottom: 1px solid rgb(0, 0, 0, 0.1);
    padding: 1rem 0;
}


@media screen and (min-width: 776px) {

    .LPFooter {
        margin-top: 2rem;
        background-color: white;
        padding: 3rem 0;
        /* width: 100vw; */

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        font-size: 1.1rem;
        width: 75vw;
        margin: auto;
    }

    .homefooter {
        border: 1px solid rgb(0, 0, 0, 0.1);
        margin-top: 5rem;
    }

    .FooterColumn {
        display: flex;
        flex-direction: column;
        color: #141414;
    }

    .FooterHeading {
        /* font-weight: bold; */
        margin: 1rem 0;
        color: white;
        font-size: 1.5rem;

    }

    .FooterColumn a {
        text-decoration: none;
        color: #141414;
    }

    .FooterColumn a:hover {
        color: #979696;
    }

    .LowerFooter {
        display: flex;
        flex-direction: column;

        background-color: white;
        height: auto;

        color: #858586;

        font-size: 1.1rem;

        justify-content: flex-start;
        align-items: center;

        border-top: 1px solid rgb(0, 0, 0, .1);
        margin: auto;
    }

    .footersocial {
        display: flex;
        justify-content: space-between;
        font-size: 3rem;
        width: 15vw;
        flex-direction: column;
    }
}


.FooterColumn .footer-links {
    font-size: 1.4rem;
    white-space: nowrap;
}