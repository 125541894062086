.RoomInfo {
    width: 85vw;
    /* min-height: 55vw; */
    border: 1px solid #E9EDEF;
    /* padding: 1rem; */
    position: absolute;
}

.RoomRow {
    display: flex;
    height: 5rem;
    justify-content: space-between;
    align-items: center;
}

.RoomInfo .leftinfo {
    display: flex;
    flex-direction: column;
    height: 5rem;
    justify-content: center;
    /* border: 1px solid black; */
}

.leftinfo>.main {
    font-weight: 500;
    size: 1.5rem;
    color: black;
}

.leftinfo>.info {
    font-weight: 400;
    size: 1rem;
    color: #858586;
}

.RoomRow {
    padding: 1rem;
}

.ant-tabs {
    background-color: white;
}

.RoomInfo .ant-tabs-extra-content {
    margin-right: 1rem;
}

@media screen and (min-width: 776px) {
    .RoomInfo {
        width: 30vw;
        /* min-height: 20vw; */
    }
}