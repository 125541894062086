.smallImagesModal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background-color: white;
}

.smallImagesClose {
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
  margin-bottom: 25vh;
  padding: 1.75rem;
}

@media screen and (min-width: 776px){
  .smallImagesModal {
    position: absolute;
      top: 40px;
      left: 40px;
      right: 40px;
      bottom: 40px;
      border: 1px solid #ccc;
      background: #fff;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      border-radius: 4px;
      outline: none;
      padding: 20px;
  }

  .smallImagesClose{
    margin-bottom: 0;
    padding: 0;
  }
}