@import '~antd/dist/antd.less';
@import "~react-image-gallery/styles/css/image-gallery.css";
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
:root {
	--vdRed: #d93b4f;
	--vdOrange: #f48148;
	scroll-behavior: smooth;
}
/* globals */
body {
	/* padding-top: 66px; */
	color: var(--mainBlack);
	background: var(--mainWhite);
	font-family: "Inter", Verdana, Geneva, Tahoma, sans-serif;
	// line-height: 1.4;
}

// .fontSize {
// 	font-size: 1.4rem;
// }


// input[type="color"],
// input[type="date"],
// input[type="datetime"],
// input[type="datetime-local"],
// input[type="email"],
// input[type="month"],
// input[type="number"],
// input[type="password"],
// input[type="search"],
// input[type="tel"],
// input[type="text"],
// input[type="time"],
// input[type="url"],
// input[type="week"],
// select:focus,
// textarea {
//   font-size: 16px !important;
// }

// h1 {
// 	font-size: 3em;
// 	line-height: 1;
// 	margin-bottom: 0.5em;
// 	color: #f7f7f7;
// }
// h2 {
// 	font-size: 2em;
// 	margin-bottom: 0.75em;
// }
// h3 {
// 	font-size: 2em;
// 	line-height: 1;
// 	margin-bottom: 1em;
// }
// .font-small {
// 	background-color: black;
// }
// h4 {
// 	font-size: 2.2em;
// 	line-height: 1.25;
// 	margin-bottom: 1.25em;
// }
// h5 {
// 	font-size: 1em;
// 	font-weight: bold;
// 	margin-bottom: 1.5em;
// }
// h6 {
// 	font-size: 1em;
// 	font-weight: bold;
// 	margin-bottom: 1.5em;
// }

// .btn-primary {
// 	display: inline-block;
// 	text-decoration: none;
// 	letter-spacing: var(--mainSpacing);
// 	color: #fff;
// 	background: var(--primaryColor);
// 	padding: 1.4rem 1.9rem;
// 	/* border: 3px solid var(--primaryColor); */
// 	transition: var(--mainTransition);
// 	text-transform: uppercase;
// 	cursor: pointer;
// 	border-color: #d9534f;
// }
// .btn-primary:hover {
// 	background: var(--primaryColor);
// 	color: #f7f7f7;
// 	border-color: #d9534f;
// }
// .loading {
// 	text-transform: capitalize;
// 	text-align: center;
// 	margin-top: 3rem;
// }
// .error {
// 	text-align: center;
// 	text-transform: uppercase;
// 	margin: 2rem 0;
// }
// .empty-search {
// 	text-align: center;
// 	text-transform: capitalize;
// 	margin: 2rem 0;
// 	padding: 1rem;
// 	letter-spacing: var(--mainSpacing);
// }
/* end of globals */
/* Navbar */
.navbar {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding: 0.75rem 2rem;
	background: var(--offWhite);
	z-index: 1;
}
.nav-header {
	display: flex;
	justify-content: space-between;
}
.nav-btn {
	background: transparent;
	border: none;
	cursor: pointer;
	outline: none;
}
.nav-icon {
	font-size: 1.5rem;
	color: var(--primaryColor);
}
.nav-links {
	height: 0;
	overflow: hidden;
	transition: var(--mainTransition);
	list-style-type: none;
}
.nav-links a {
	display: block;
	text-decoration: none;
	padding: 1rem 0;
	color: var(--mainBlack);
	transition: var(--mainTransition);
	text-align: center;
	font-size: 1rem;
	font-weight: 600;
	letter-spacing: var(--mainSpacing);
}
.nav-links a:hover {
	color: var(--primaryColor);
}

.show-nav {
	height: 100px;
}
@media screen and (min-width: 768px) {
	.nav-btn {
		display: none;
	}
	.nav-center {
		max-width: 1170px;
		margin: 0 auto;
		display: flex;
	}
	.nav-links {
		height: auto;
		display: flex;
		margin-left: 4rem;
	}
	.nav-links a {
		margin: 0 1rem;
		padding: 0.5rem 0;
	}
}
/* end of navbar */
/* Hero */
.defaultHero,
.roomsHero {
	min-height: calc(100vh - 66px);
	// background: url("./images/bg2.jpg") center/cover no-repeat;
	display: flex;
	align-items: center;
	justify-content: center;
}
.roomsHero {
	// background-image: url("./images/roomsbg.jpg");
	min-height: 60vh;
}
/* End of Hero */
/* Banner */
.banner {
	display: inline-block;
	background: rgba(0, 0, 0, 0.5);
	color: var(--mainWhite);
	padding: 2rem 1rem;
	text-align: center;
	text-transform: capitalize;
	letter-spacing: var(--mainSpacing);
}
.banner h1 {
	font-size: 2.5rem;
}
.banner div {
	width: 16rem;
	height: 2px;
	background: var(--primaryColor);
	margin: 1.7rem auto;
}
.banner p {
	font-size: 1.2rem;
	margin-bottom: 2rem;
}
@media screen and (min-width: 576px) {
	.banner {
		padding: 2rem 3rem;
	}
	.banner h1 {
		font-size: 3rem;
	}
}
@media screen and (min-width: 992px) {
	.banner {
		padding: 2rem 6rem;
	}
	.banner h1 {
		font-size: 4rem;
	}
}
/* End of Banner */
/* Title */
.section-title {
	text-align: center;
	margin-bottom: 4rem;
}
.section-title h4 {
	font-size: 3.5rem;
	letter-spacing: var(--mainSpacing);
	text-transform: capitalize;
	margin-bottom: 1rem;
}
.section-title div {
	width: 5rem;
	height: 5px;
	margin: 0 auto;
	background: var(--primaryColor);
}
/* end of Title */

/* services */
.services {
	padding: 5rem 0;
}
.services {
	background: var(--darkGrey);
	text-align: center;
}
.services-center {
	width: 90vw;
	margin: 0 auto;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
	grid-row-gap: 2rem;
	grid-column-gap: 50px;
}
.service span {
	display: inline-block;
	color: var(--primaryColor);
	font-size: 2.5rem;
	margin-bottom: 1.5rem;
}
.services h6 {
	letter-spacing: var(--mainSpacing);
}
.services p {
	width: 80%;
	margin: 0 auto;
}
@media screen and (min-width: 992px) {
	.services-center {
		width: 95vw;
		max-width: 1170px;
	}
}

@media screen and (min-width: 1200px) {
	.services p {
		width: 100%;
	}
}
/*end of services */
/* featured rooms */

.featured-rooms {
	padding: 5rem 0;
}
.featured-rooms-center {
	width: 80vw;
	margin: 0 auto;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
	grid-row-gap: 2rem;
	grid-column-gap: 40px;
}
@media screen and (min-width: 776px) {
	.featured-rooms-center {
		width: 90vw;
		grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
	}
}
@media screen and (min-width: 992px) {
	.featured-rooms-center {
		width: 95vw;
		max-width: 1170px;
	}
}
/* end pf featured rooms */
/* room */
.room {
	box-shadow: var(--lightShadow);
	transition: var(--mainTransition);
}
.room:hover {
	box-shadow: var(--darkShadow);
}

.img-container {
	position: relative;
}
.img-container img {
	width: 100%;
	display: block;
	transition: var(--mainTransition);
}
.price-top {
	position: absolute;
	top: 0;
	left: 0;
	background: var(--primaryColor);
	color: var(--mainWhite);
	padding: 0.8rem 1.6rem 0.1rem 1.2rem;
	border-bottom-right-radius: 1rem;
	font-size: 1rem;
	text-align: center;
	transition: var(--mainTransition);
}
.price-top h6 {
	margin-bottom: 0;
	font-size: 1rem;
	font-weight: 300;
	color: #fff;
	letter-spacing: var(--mainSpacing);
}
.room-link {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: scale(0);
	transition: all 0.3s linear;
}
.img-container:hover {
	background: rgba(0, 0, 0, 0.8);
}
.img-container:hover img {
	opacity: 0.3;
}
.img-container:hover .price-top {
	opacity: 0;
}
.img-container:hover .room-link {
	transform: translate(-50%, -50%) scale(1);
}
.room-info {
	background: #2c3335;
	text-transform: capitalize;
	padding: 0.5rem 0;
	text-align: center;
	font-weight: 700;
	letter-spacing: var(--mainSpacing);
	color: #fff;
}
/* end of room  */
/* single room*/

.single-room {
	padding: 5rem 0 0 0;
}
.single-room-images {
	width: 80vw;
	margin: 0 auto;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
	grid-row-gap: 2rem;
	grid-column-gap: 50px;
}
.single-room-images img {
	width: 100%;
	display: block;
}
.single-room-info {
	width: 80vw;
	display: grid;
	grid-template-columns: 1fr;
	margin: 2rem auto;
}

.addToCart {
	display: flex;
	align-items: center;
	justify-content: center;

	margin: 5rem;
}
.desc,
.info {
	margin: 1rem 0;
}
.desc h3 {
	text-transform: capitalize;
	letter-spacing: var(--mainSpacing);
}
.desc p {
	line-height: 1.5;
}
.info h3,
.info h6 {
	text-transform: capitalize;
	letter-spacing: var(--mainSpacing);
}

.info h6 {
	font-weight: 300;
}
.room-extras {
	width: 80vw;
	margin: 0 auto 3rem auto;
}
.room-extras h6 {
	text-transform: capitalize;
	letter-spacing: var(--mainSpacing);
}
.extras {
	list-style-type: none;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
	grid-column-gap: 2rem;
	grid-row-gap: 1rem;
}
@media screen and (min-width: 992px) {
	.single-room-images,
	.single-room-info,
	.room-extras {
		width: 95vw;
		max-width: 1170px;
	}
	.single-room-info {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 2rem;
	}
	.info {
		padding-left: 3rem;
	}
}
/* end of single room*/
/* roomlist */
.roomslist {
	padding: 1rem 0;
	display: flex;
  flex-direction: column;
  /* background: linear-gradient(180deg, rgba(100,151,172,1) 0%, rgba(185,209,219,1) 100%);  */


}
.roomslist-center {
	width: 70vw;
	/* margin: 0 auto; */

	margin: 2rem auto;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
	grid-row-gap: 2rem;
	grid-column-gap: 30px;
}

/* App.css */
.orangeSearch {
	width: 90vw;
	height: auto;
	background-color: transparent;

	margin: 0 auto;
	border-radius: 2rem;

	display: none;
  flex-direction: column;
  /* flex-shrink: 1; */

}

.orangeSearchInput {
  width: 90%;
  margin: auto;
  margin-bottom: 1rem;
  border-radius: 5px;

  /* color: #fff; */
}

.whatAreYouLookingFor {
	font-size: 1.5rem;
	color: white;
	padding: 1rem;
	border: 1px solid white;
	border-radius: 1rem;
	margin: 2rem auto;
	margin-bottom: 0;

	width: 90%;

	display: flex;
}

.orangeSearchbar {
	width: 90%;
	height: 5rem;
	font-size: 1.5rem;
	padding: 1rem;
	margin: 0 auto;
	margin-top: 0;
	margin-bottom: 1rem;

	border-radius: 10px;
	border: 0;
	border: 2px solid #f48244;
}

.orangeSearchbarSearchButton {
	display: flex;
	justify-content: flex-end;
	width: 90%;
	margin: 0 auto;

	border-radius: 5px;
}

@media screen and (min-width: 776px) {
	.roomslist-center {
		width: 70vw;
	}
}
@media screen and (min-width: 992px) {
	.roomslist {
		padding: 1rem 0;
		display: flex;
    flex-direction: row;
    background: white;
    background-color: white;
	}

	.roomslist-center {
		width: 70vw;
		/* margin: 0 auto; */
		margin-left: auto;
		/* margin-right: 5rem; */
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
		grid-row-gap: 2rem;
		grid-column-gap: 30px;
	}

	.orangeSearch {
		width: 30vw;
		height: fit-content;
		background-color:transparent;
		position: sticky;
		top: 75px;
		margin: 0 2rem;
		border-radius: 2rem;

		display: flex;
		flex-direction: column;
	}

	.orangeSearchbar {
		width: 90%;
		height: 4rem;
		font-size: 1.5rem;
		padding: 1rem;
		margin: 0 auto;
		margin-top: 0;
		margin-bottom: 2rem;
		border-radius: 5px;
		border: 0;
	}
}

@media screen and (min-width: 1300px) {
	.orangeSearch {
		width: 20vw;
	}
}
/* end of roomlist */
/*  rooms fitler*/
.filter-container {
	padding: 5rem 0;
}
.filter-form {
	width: 60vw;
	margin: 0 auto;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(202px, 1fr));
	grid-row-gap: 2rem;
	grid-column-gap: 40px;
}
.form-group {
	text-transform: capitalize;
}
.form-group label {
	display: block;
	font-size: 1.4rem;
	letter-spacing: var(--mainSpacing);
	margin-bottom: 0.5rem;
}
.form-control {
	width: 100%;
	background: transparent;
	font-size: 1.4rem;
}

select.form-control:not([size]):not([multiple]) {
	height: calc(3.25rem + 2px);
}

.size-inputs {
	display: flex;
}
.size-input {
	width: 40%;
	padding: 0.2rem;
	border: 1px solid var(--mainBlack);
	border-radius: 0.3rem;
	margin-right: 0.3rem;
}
.single-extra label {
	display: inline-block;
	font-size: 1.4rem;
	margin-left: 0.5rem;
}
@media screen and (min-width: 776px) {
	.filter-form {
		width: 70vw;
	}
}
@media screen and (min-width: 992px) {
	.filter-form {
		width: 95vw;
		max-width: 1170px;
	}
}

.fb_dialog {z-index: 1000 !important;}

/* end of rooms fitler*/

@primary-color: #d93b4f;@border-radius-base: 0px;@picker-date-hover-range-color: #f48148;@checkbox-color: rgba(244,130,71,0.1);@checkbox-check-color: #F48247;@slider-track-background-color: #F48247;@slider-rail-background-color: rgba(244,130,71,0.2);@slider-handle-size: 2rem;@slider-handle-border-width: 1px;