.Header {
    display: flex;
    flex-direction: row;
    height: auto;
    width: 100vw;
    align-self: flex-start;

    background-color: transparent;
    align-items: center;

    /*border-bottom: 1px solid #E9EDEF;*/
}

.Header .innercontainer {
    margin: auto;
    display: flex;
    align-items: center;
    width: 90vw;
}

.Header .logo,
.Header .logo-business,
.Header .logo-null {
    margin-right: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("./../../images/Home/logo-full.png");
    /*background-image: url("./../../images/provider/partner_yoodo.png");*/
    height: 8vh;
    width: 45vw;
    margin-top: 10px;
}

.Header .logo-yoodo {
    margin-right: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("./../../images/provider/partner_yoodo.png");
    /*background-image: url("./../../images/provider/partner_yoodo.png");*/
    height: 12vh;
    width: 45vw;
    margin-top: 10px;
}

.Header .logo-yoodo.white {
    background-image: url("./../../images/provider/partner_yoodo.png");
}

.Header .logo-indosat,
.Header .logo-business-dashboard {
    margin-right: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("./../../images/provider/indosat_vd.svg");
    /*background-image: url("./../../images/provider/partner_yoodo.png");*/
    height: 8vh;
    width: 45vw;
    margin-top: 10px;
}

.Header .logo-indosat.white {
    background-image: url("./../../images/provider/indosat_vd.svg");
}

.Header .buttonContainer {
    display: flex;
    align-items: center;
    width: auto;
}

.Header .button {
    font-family: 'Inter', sans-serif;
    background-color: transparent;
    background: transparent;
    height: auto;
    width: auto;
    margin-left: 2rem;
    border-color: transparent;
}

.Header .button:hover {
    cursor: pointer;
}


.Header .welcome {
    font-family: 'Inter';
    font-weight: 700;
}

@media screen and (min-width:776px) {
    .Header .innercontainer {
        width: 75vw;
    }
}

.header-currency-dropdown .ant-select-focus .ant-select-selection {
    border: none !important;
}

.header-currency-dropdown .ant-select-selection--single {
    background: transparent;
    color: #d93b4f;
    border: none !important;
    margin-right: 5px;
    font-size: 16px;
}

.header-signin-container-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

@media screen and (max-width:775px) {

    .Header .logo,
    .Header .logo-indosat,
    .Header .logo-yoodo {
        height: 6rem;
    }

}


.text-dark {
    color: #000 !important;
    font-weight: bold;
}

.text-primary {
    color: #d93b4f !important;
    font-weight: bold;
}