.LandmarkContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.Landmarks {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    border-bottom: 1px solid #E9EDEF;
    width: auto;
    align-items: center;
}

.viewMore {
    border-radius: 1rem;
    cursor: pointer;
    background-color: #0075d2;
    height: 2rem;
    width: 20vw;
    margin: 1rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 992px) {
    .LandmarkContainer {
        flex-wrap: wrap;
        flex-direction: row;
    }
    .Landmarks {
        width: 25vw;
    }
    .viewMore {
        height: 3rem;
        width: 5vw;
    }
}