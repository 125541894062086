.NewsLetter {
  height: 35vh;
  margin: 2rem 1.75rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.NewsLetter .NewsLetterHeading {
  color: white;
  font-size: 2rem;
  font-weight: bold;
}

.NewsLetter .NewsLettersubHeading {
  color: #F48247;
  font-size: 1.5rem;
}

.rowflex {
  display: flex;
  background-color: white;
  padding: 1rem;
  border-left: 2px solid #d93b4f;
  margin: 1rem auto;
}

.NewsLetter .NewsletterContainer {
  width: 80vw;
}

.NewsLetter .spacing {
  width: 5vw;
}

.rowflex input {
  border: none;
  width: 70%;
}

@media screen and (min-width: 776px) {
  .NewsLetter {
    flex-direction: row;
    width: 75vw;
    padding: 15vh;margin: 0 auto;
  }

  .NewsLetter .NewsletterContainer {
    width: 25vw;
  }

  .NewsLetter .NewsLetterHeading {

    font-size: 3rem;

  }
}

.indosat-news-letter-container {
  width: 90vw;
  margin: 3rem auto 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.indosat-news-letter {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indosat-news-letter .ant-input {
  width: 60%;
  height: 60px;
}

.indosat-subscribe-btn {
  background-color:#d93b4f;
  color: #fff;
  border: 1px solid;
  width: 30%;
  height: 60px;
}

@media screen and (min-width: 776px) {
  .indosat-news-letter-container {
    width: 75vw;
    margin: 3rem auto 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .indosat-news-letter {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .indosat-news-letter .ant-input {
    width: 40%;
    height: 40px;
  }
  .indosat-subscribe-btn{
    background-color:#d93b4f;
    color: #fff;
    border: 1px solid;
    width: 20%;
    height: 40px;
  }
}

