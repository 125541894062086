.login-form {
    max-width: 350px;
}

.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
}

.loginModal .ant-form-item {
    margin-bottom: 4px !important;
}

.loginModal .ant-form-item-label {
    line-height: normal;
}

.loginModal label {
    font-size: 12px !important;
    line-height: normal !important;
    font-family: "Inter", sans-serif !important;
    font-weight: 600 !important;
}

.signUpPhoneInput {
    width: 100% !important;
    border-radius: 0;
}

.loginModal .text {
    font-size: 12px !important;
}

.ant-form-item-children {
    font-size: 12px !important;
}

.login-form .react-tel-input .form-control:focus {
    box-shadow: 0 0 0 2px rgba(217, 59, 79, 0.2);
}

.login-form .react-tel-input .flag-dropdown {
    border: none;
}

@media screen and (min-width: 768px) {
    .login-form {
        width: 350px;
    }
}