.booking .flexrow {
  display: flex;
  justify-content: space-between;
}

.booking .heading {
  font-size: 2rem;
}

.booking .subheading {
  font-size: 1.75rem;
  font-weight: 425;
}

.booking .bookingCard {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #E9EDEF;
  padding: 1rem 0;
  margin: 1rem 0;
}

.bookingCard .bookingCardInfo>div {
  margin-top: 1rem;
}

.booking .faded {
  font-weight: 200;
  color: #858586;
}

.booking .bookingCardImage {
  background-repeat: no-repeat;
  background-size: cover;
  width: auto;
  height: 15rem;
  width: auto;
  margin: 1rem 0;
}

.booking .checkindata {
  width: 75%;
}

.booking {
  padding: 1.75rem;
}

.bookingCardContainer {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 776px) {
  .booking .bookingCard {
    flex-direction: row;
    width: calc((75vw - 4rem) / 2);
    justify-content: space-between;
    border: 1px solid #E9EDEF;
    padding: 0;
    margin: 2rem 0;
  }
  .bookingCard:hover {
    border: 1px solid lightgray;
    cursor: pointer;
  }
  .booking .bookingCardImage {
    width: 12vw;
    margin: 0;
    height: 100%;
  }
  .booking {
    width: 75vw;
    margin: auto;
    padding: 0;
  }
  .bookingCardContainer {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .booking .checkindata {
    width: calc(100% - 2rem);
  }
  .booking .bookingCardInfo {
    margin: 1rem;
    margin-top: 0;
    flex-shrink: 0;
    width: calc(100% - 13vw);
  }
}